import React, { useState, useEffect } from 'react';
import { Box, NativeSelect, FormControl, InputLabel, Snackbar,  CircularProgress, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../assets/images/editIcon.svg';
import DeleteIcon from '../../assets/images/DeleteIcon.svg';
import AddEditDevice from './AddEditDevice.js'
import DeleteModal from './DeleteModal'
import axios from "axios";
import './style.scss'

const DevicesComp = () => {
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState('Delete Successfully.');
    const [selectedFloor, setSelectedFloor] = useState(1);
    const [rows, setRows] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [actionButton, setActionButton] = useState("")
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        {
            field: 'name',
            headerName: 'Device Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'deviceMake',
            headerName: 'Device Make',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'serialNumber',
            headerName: 'Serial Number',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'installed',
            headerName: 'Date Installed ',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'macAddress',
            headerName: 'MACAddress',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'ipAddress',
            headerName: 'IP Address',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'topicName',
            headerName: 'Topic Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            sortable: false,
            disableColumnMenu: true,
            hideable: false,
            renderCell: (params) => (

                <div>
                    <img
                        src={EditIcon}
                        alt="edit"
                        style={{ width: '28px', height: '28px', cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleEdit(params.row)}
                    />
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                        onClick={() => { setOpenDeleteModal(true); setDeviceIdToDelete(params.id) }}
                    />
                </div>
            ),
        }
    ]
    const handleEdit = (deviceData) => {
        setEditData(deviceData);
        setActionButton("edit");
        setOpen(true);
    };

    const selectedFloorHandler = (e) => {
        setSelectedFloor(e.target.value)
    }

    const processedRows = Array.isArray(rows) ? rows.map(row => (
        {
            id: row.id,
            name: row.name || 'N/A',
            cameraType: row.cameraType || 'N/A',
            streamUrl: row.streamUrl || 'N/A',
            videoLength: row.videoLength || 'N/A',
            deviceMake: row.deviceMake || 'N/A',
            serialNumber: row.serialNumber || 'N/A',
            installed: row.installed || 'N/A',
            macAddress: row.macAddress || 'N/A',
            ipAddress: row.ipAddress || 'N/A',
            topicName: row.topicName || 'N/A',
            floorId: row.floorId || 'N/A',
            deviceLocationId: row.deviceLocationId || null,
            type: row.type || 'N/A',
            status: row.status || 'N/A',
            createdBy: row.createdBy || 'N/A',
            cssXCoordinate: row.cssXCoordinate || 0,
            cssYCoordinate: row.cssYCoordinate || 0,
            triggeredBy: row.triggeredBy.split(',').map(Number) || [0],
        })) : [];

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/device/data/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setRows(response.data)
                setIsLoading(false)
            }
        } catch (error) {
            console.error("Error making request:", error);
            setIsLoading(false)
        }
    };
    useEffect(() => {
        fetchData();
    }, [actionButton]);

    const handleDelete = async (id) => {
        let token = JSON.parse(localStorage.getItem("token"));
        const url = `${process.env.REACT_APP_DEVICE_API_URL}/device/${id}`;
        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status == 200) {
                fetchData();
                setOpenToast(true)
            } else {
                console.error("Failed to delete device:", response.data);

            }
        } catch (error) {
            console.error("Error deleting device:", error);
        }
    };
    const handleToastClose = () => {
        setOpenToast(false)
        setMessage("")
    };
    return (
        <>
            <div className="deviceMangement_wrapper addDevicemanage">
                <div
                    className="main_heading"
                >
                    <h1>Device Management</h1>
                </div>
                <div className="AddFloor">
                    <button className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Add Device</button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper  deviceMangeTable_wrapper">
                        {isLoading && processedRows.length === 0 ? <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress />
                        </Box> 
                        : <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={processedRows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        }
                    </div>
                </div>
            </div>
            <AddEditDevice
                open={open}
                setOpen={setOpen}
                setActionButton={setActionButton}
                actionButton={actionButton}
                editData={editData}
                rows={rows}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={openToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={message}
                onClose={handleToastClose}
                className={"success"}
            />
        </>

    )
}

export default DevicesComp
