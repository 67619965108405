import {combineReducers} from 'redux'
import FloormapReducer from './FloormapReducer'
import LogInReducer from './LogInReducer'
import SnackbarReducer from "./snackbarSlice";
const rootReducer = combineReducers({
    snackbar : SnackbarReducer,
    floormap : FloormapReducer,
    login: LogInReducer
})

export default rootReducer;