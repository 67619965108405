export const fieldUser = [
    {
        mainHead: "Email",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "SMS",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "Push Notification",
        desText: "A Brief Description goes here",
        disabled: true
    },
]

export const prefrenceData = [
    {
        id: "videoLength",
        name: "videoLength",
        label: "Video Length",
        type: "select",
        data: [
            { "value": "10", "label": "10" },
            { "value": "20", "label": "20" },
            { "value": "30", "label": "30" },
        ]
    },
    {
        id: "flashCount",
        name: "flashCount",
        label: "Number of Light Flashes",
        type: "select",
        data: [
            { "value": "1", "label": "1" },
            { "value": "2", "label": "2" },
            { "value": "3", "label": "3" },
            { "value": "4", "label": "4" },
            { "value": "5", "label": "5" },
            { "value": "6", "label": "6" },
            { "value": "7", "label": "7" },
            { "value": "8", "label": "8" },
            { "value": "9", "label": "9" },
            { "value": "10", "label": "10" },
        ]
    },
    {
        id: "speakerCount",
        name: "speakerCount",
        label: "Number of Speaker Plays",
        type: "select",
        data: [
            { "value": "1", "label": "1" },
            { "value": "2", "label": "2" },
            { "value": "3", "label": "3" },
            { "value": "4", "label": "4" },
            { "value": "5", "label": "5" },
            { "value": "6", "label": "6" },
            { "value": "7", "label": "7" },
            { "value": "8", "label": "8" },
            { "value": "9", "label": "9" },
            { "value": "10", "label": "10" },
        ]
    },
]
