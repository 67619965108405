import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import mqttWaringIcon from '../../assets/images/mqttWaringIcon.svg';
import mqttHealthyIcon from '../../assets/images/mqttHealthyIcon.svg';
import mqttCriticalIcon from '../../assets/images/mqttCriticalIcon.svg';
import connected from '../../assets/images/connected.svg';
import disconnected from '../../assets/images/disconnected.svg';

import './HealthStatus.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const HealthCard = ({ status,cardHeading }) => {
  const data = [
    { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 }
  ];

  const isSuccess = status
  const strokeColor = isSuccess == "healthy" ? '#478F96' :  isSuccess == "critical" ? '#CA6B6E' : '#F3A53F';
  const gradientId =  isSuccess == "healthy" ? 'colorUvSuccess' : isSuccess == "critical" ? 'colorUvCritical' :'colorUvWarning';
  return (   
     <>
       <Col md={4} xl={4}>
        <div className="healthCard">
          <div className="statusWrapper"> 
            <div className={`${isSuccess}Bg healtBeat`}><img src={isSuccess == "healthy" ? mqttHealthyIcon : isSuccess == "critical" ? mqttCriticalIcon : mqttWaringIcon} /> <p>{isSuccess}</p></div>
            {/* <div className="statusShow"><span><img src={isSuccess == "healthy" ? connected : disconnected} /> </span> <p>{isSuccess == "healthy" ? "Connected" : "Disconnected"}</p></div> */}
          </div>
          <h3>{cardHeading}</h3>
          <div className="graph">
            <ResponsiveContainer width="100%" height={73}>
              <AreaChart
                data={data}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5
                }}
              >
                 {/* <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis /> */}
                 <defs>
                  <linearGradient id="colorUvSuccess" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="16.01%" stopColor="#E2FFEF" stopOpacity={1} />
                    <stop offset="100%" stopColor="rgba(221, 242, 244, 0)" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorUvWarning" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#FFF1E0" stopOpacity={1} />
                    <stop offset="100%" stopColor="rgba(251, 235, 214, 0)" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorUvCritical" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="rgba(255, 220, 221, 0.4)" stopOpacity={1} />
                    <stop offset="95.04%" stopColor="rgba(202, 107, 110, 0)" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area type="monotone" dataKey="uv" stroke={strokeColor} fill={`url(#${gradientId})`} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
     </>
    
  );
};

export default HealthCard;
