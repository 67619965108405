const empty = value => {
    const val = value ? value.trim() : value;
    return !Boolean(val);
};

const username = value => {
    const reUser = /^[A-Za-z0-9.]+$/;
    return !empty(value) && reUser.test(value);
};

const length = value => {
    const reUser = /^[A-Za-z0-9.]+$/;
    return !empty(value) && value?.length <= 50 && reUser.test(value);
};

const password = value => {
    const re = /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&])(?=.{8,20}).*$/;
    return !empty(value) && value?.length >= 6 && re.test(value);
};

const email = value => {
    const reemail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return !empty(value) && reemail.test(value);
};

export const Validation = {
    empty,
    username,
    length,
    password,
    email,
};