import React, { useState, useEffect } from "react";
import { Switch, Stack, FormGroup, Button, styled, TextField, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { toggleSnackbar } from "../../../reducers/snackbarSlice";
import icon from "../../../assets/images/drill-dialog.png";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  borderRadius: 16,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(8, 109, 171, 1)",
        ...theme.applyStyles("dark", {
          backgroundColor: "rgba(8, 109, 171, 1)",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 16,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
const SecuritySettings = () => {
  const [settings, setSettings] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [showDrillModeSnackbar, setShowDrillModeSnackbar] = useState(false);
  const [drillModeMessage, setDrillModeMessage] = useState("");
  const [pendingDrillModeValue, setPendingDrillModeValue] = useState(""); // Pending drill mode value
  const [isDrillModeDialogOpen, setIsDrillModeDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const enableSnackbar = useSelector((state) => state.snackbar.enableSnackbar);
  const isLockdown = localStorage.getItem("isLockdownActive");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    setUserRole(decodedToken.userRole);
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`);
        const fetchedSettings = {};

        response.data.allGlobalSettings.forEach((item) => {
          fetchedSettings[item.code] = item.value;
        });

        setSettings(fetchedSettings);
        setInitialSettings(fetchedSettings);

        const storedDrillMode = localStorage.getItem("drill_mode");

        if (storedDrillMode === "1") {
          setShowDrillModeSnackbar(true);
          setDrillModeMessage("Drill Mode is enabled");
          dispatch(toggleSnackbar(true));
        } else if (storedDrillMode === "0") {
          setShowDrillModeSnackbar(true);
          setDrillModeMessage("Drill Mode is disabled");
          dispatch(toggleSnackbar(false));
        }

      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const hasChanges = Object.entries(settings)
    .filter(([key]) => key !== "drill_mode") // Exclude drill_mode from comparison
    .some(([key, value]) => value !== initialSettings[key]);

  const handleSubmit = async () => {
    if (!settings.alarm_pin) {
      setPinError(true);
      return;
    }

    // Prepare the update data excluding drill_mode
    const updateData = Object.entries(settings)
      .filter(([key]) => key !== "drill_mode") // Exclude drill_mode
      .map(([key, value]) => ({
        code: key,
        value,
      }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/updateGlobalSettingCode`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessSnackbar(true);
        setInitialSettings({ ...settings }); // Update initialSettings without drill_mode changes
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setShowSuccessSnackbar(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setShowSuccessSnackbar(false);
    setShowDrillModeSnackbar(false);
  };

  const handleToggle = (key) => {
    setSettings((prev) => ({
      ...prev,
      [key]: prev[key] === "1" ? "0" : "1",
    }));
  };

  const handleDrillModeToggle = (value) => {
    setPendingDrillModeValue(value);
    setIsDrillModeDialogOpen(true);
  };

  const handleDialogClose = () => {
    setPendingDrillModeValue(settings.drill_mode);
    setIsDrillModeDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    setIsDrillModeDialogOpen(false);
    setSettings((prev) => ({ ...prev, drill_mode: pendingDrillModeValue }));

    const updateData = [
      { code: "drill_mode", value: pendingDrillModeValue },
    ];

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/updateGlobalSettingCode`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          },
        }
      );

      if (response.status === 200) {
        const newDrillModeMessage =
          pendingDrillModeValue === "1"
            ? "Drill Mode is enabled"
            : "Drill Mode is disabled";

        setDrillModeMessage(newDrillModeMessage);
        setShowDrillModeSnackbar(true);

        localStorage.setItem("drill_mode", pendingDrillModeValue);
        dispatch(toggleSnackbar(pendingDrillModeValue === "1"));
      }
    } catch (error) {
      console.error("Error updating drill mode:", error);
    }
  };

  const handlePinChange = (e) => {
    const newValue = e.target.value;

    if (/^[a-zA-Z0-9]*$/.test(newValue) || e.nativeEvent.inputType === "deleteContentBackward") {
      setSettings((prev) => ({ ...prev, alarm_pin: newValue }));
      setPinError(false);
    } else {
      setPinError(true);
    }
  };

  const togglePasswordVisibility = () => setShowPin(!showPin);

  return (
    <>
      <div className="notifications_wrapper">
        <div className="noti">
          <div className="sc_heading">
            <p>Multi Factor Authentication</p>
            <span>If enabled, the user will have to go through the multi factor authentication when logging in.</span>
          </div>
          <div className="sc_toggle">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={settings.mfa_enabled === "1"}
                  onChange={() => handleToggle("mfa_enabled")}
                  inputProps={{ "aria-label": "MFA toggle" }}
                />
              </Stack>
            </FormGroup>
          </div>
        </div>
        <div className="noti">
          <div className="sc_heading">
            <p>Drill Mode</p>
            <span>If enabled, the application will work in Drill Mode. Certain functionalities will be disabled.</span>
          </div>
          <div className="sc_toggle">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  checked={settings.drill_mode === "1"}
                  onChange={(e) => handleDrillModeToggle(e.target.checked ? "1" : "0")}
                  inputProps={{ "aria-label": "Drill Mode toggle" }}
                  disabled={!(userRole === "SUPER_ADMIN" || userRole === "ADMIN") || isLockdown === "true"}
                />
              </Stack>
            </FormGroup>
          </div>
        </div>
        <div className="noti">
          <div className="sc_heading">
            <p>Set Override PIN</p>
            <span>Enter a PIN to reset the alarm. This will override the existing PIN.</span>
          </div>
          <div className="pin-wrapper">
            <TextField
              label=" Enter PIN"
              type={showPin ? "text" : "password"}
              variant="outlined"
              value={settings.alarm_pin || ""}
              onChange={handlePinChange}
              error={pinError}
              InputProps={{
                style: {
                  borderRadius: "4px",
                  fontFamily: "Barlow Semi Condensed",
                  height: "45px",
                  fontSize: "15px",
                  width: "120px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {pinError && (
          <div style={{ color: "red", marginTop: "10px", fontFamily: "Barlow Semi Condensed" }}>
            {"Please enter a valid PIN"}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!hasChanges}
            sx={{
              backgroundColor: !hasChanges ? "#BFC0C9" : "#086DAB",
              color: "white",
              textTransform: "none",
            }}
          >
            Save
          </Button>
        </div>

        {/* Show success Snackbar only if not in drill mode */}
        {!enableSnackbar && !showSuccessSnackbar && (
          <Snackbar
            open={showDrillModeSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              zIndex: 1200,
              pointerEvents: "none",
              position: "fixed"
            }}
          >
            <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: "100%" }}>
              {drillModeMessage}
            </Alert>
          </Snackbar>
        )}

        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
            Setting Saved Successfully
          </Alert>
        </Snackbar>
      </div>

      {isDrillModeDialogOpen && (
        
        <div className="confirmation-overlay">
          <div className="confirmation-modal">
            <div className="confirm-header">
              <img src={icon} alt="img" />
              <h2>
                {pendingDrillModeValue === "1"
                  ? "Enable"
                  : "Disable"}
              </h2>
            </div>
            <p>
              {pendingDrillModeValue === "1"
                ? "Are you sure you want to enable Drill Mode?"
                : "Are you sure you want to disable Drill Mode?"}
            </p>
            <div className="drill-btn-container">
              <Button onClick={handleDialogClose} color="primary" className="cancel-button">
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm} color="primary" className="confirm-button">
                Yes
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SecuritySettings;
