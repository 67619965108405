import React, { useState, useEffect,useRef, useCallback} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HealthCard from './HealthCard';
import mqtt from "mqtt";
import './HealthStatus.scss'
import axios from 'axios';
const HealthStatus = () => {
    const [mqttStatus, setMqttStatus] = useState('critical')
    const [javaServerStatus, setJavaServerStatus] = useState('critical')
    const mqttClient = useRef(null);

    const fetchJavaData = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('token'));
            const url = `${process.env.REACT_APP_SERVER_API_URL}/app-status`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setJavaServerStatus('healthy')
            }
        } catch (error) {
            console.error('Error fetching video data:', error);
            setJavaServerStatus('critical')
        }
    };
    const handleConnect = useCallback(() => {
        window.mqttConnected = true;
        setMqttStatus('healthy');
        console.log('MQTT Connected');
      }, []);
    
      const handleError = useCallback((err) => {
        window.mqttConnected = false;
        setMqttStatus('critical');
        console.error('MQTT Connection Error:', err);
      }, []);
    
      const handleClose = useCallback(() => {
        window.mqttConnected = false;
        setMqttStatus('critical');
        console.log('MQTT Connection Closed');
      }, []);
    
    
    useEffect(() => {
        fetchJavaData();
        mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL,
            {
              username: process.env.REACT_APP_MQTT_USERNAME,
              password: process.env.REACT_APP_MQTT_PASSWORD,
            }
          );

          mqttClient.current.on("connect", handleConnect);
          mqttClient.current.on("error", handleError);
          mqttClient.current.on("close", handleClose);
      
      
          return () => {
            if (mqttClient.current) {
              mqttClient.current.end();
            }
          };
    }, [handleConnect, handleError, handleClose]);


    // useEffect(() => {
    //     const fetchData = async () => {
    //       const username = 'd86f161a6cb77104';
    //       const password = 'QIU9A6TwgVDDQhujM1vlKJZ9CNGWY9BP6HM9BkxagJwBTNO';
    
    //       try {
    //         const response = await axios.get('https://mqtt.lockdownexperts.biz:18084/api/v5/nodes', {
    //           auth: {
    //             username: username,
    //             password: password,
    //           },
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //         });
    //         console.log(response.data,"mqtt Response Data");
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //       }
    //     };
    
    //     fetchData();
    //   }, []);
    
    return (
        <>
            <div className="healthStatusWrapper">
                <div className="main_heading">
                    <h1>System Health Status</h1>
                </div>
                <div className="inner_Wrapper healthStatus_innerwrap">
                    <div className="statusColorWrapper">
                        <ul>
                            <li><span className="healthy"></span>Healthy</li>
                            <li><span className="warning"></span>Warning</li>
                            <li><span className="critical"></span>critical</li>
                        </ul>
                    </div>
                    <div className="healthCard_wrapper">
                        <Row>
                            <HealthCard status={mqttStatus} cardHeading={"MQTT"} />
                            <HealthCard status={javaServerStatus} cardHeading={"Java server"} />
                            {/* <HealthCard status={'critical'}cardHeading={"VISTA panel"} />
                            <HealthCard status={'healthy'} cardHeading={"JACE controller"} />
                            <HealthCard status={'healthy'} cardHeading={"Peplink router"} />
                            <HealthCard status={'warning'}cardHeading={"Niagara system"} /> */}
                        </Row>
                    </div>
                </div>
            </div>

        </>
    );
};

export default HealthStatus;
