import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Modal,
    ListItemText,
    Checkbox,
    FormHelperText,
    CircularProgress,
    Snackbar
} from '@mui/material';
import closeIcon from '../../assets/images/closeicon.svg';
import axios from "axios";
import './overrideDevice.scss';
import { jwtDecode } from "jwt-decode";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};

const OverrideDevice = (props) => {
 const {setOverRideDeviceModal,overRideDeviceModal,successVerified,setSuccessVerified,setHoveredDeviceId, heading= "Deactivate Device",} = props
 const [pin, setPin] = useState("");
 const [loading, setLoading] = useState(false);
 const [openToast, setOpenToast] = useState(false);
 const [message, setMessage] = useState('');
 const [classForToast, setClassForToast] = useState("")
 const handleClose = () => {
    setOverRideDeviceModal(false)
    setOpenToast(false)
    setSuccessVerified(false)
    setMessage("")
    setClassForToast("")
    setPin("")
    setHoveredDeviceId(null)
};
const toBase64 = (str) => {
    return btoa(str);
};
const handleVerify = async () => {
    const base64Pin = toBase64(pin);
    try {
        const token = JSON.parse(localStorage.getItem('token'));
        const decodedToken = jwtDecode(token);
      
        setLoading(true);
            const response = await axios.post(
                'https://lockdownexperts.biz/identity-api/v1/appSetting/validate-pin', 
                { 
                    pin: base64Pin,
                    userEmail:decodedToken.email, 
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
        if(response.status === 200){
            setOpenToast(true)
            setMessage(response?.data?.message ? response?.data?.message : "Valid pin") 
            setClassForToast("success")
            setSuccessVerified(true)
        }    
    } catch (error) {
        console.error("Error verifying pin:", error);
        setOpenToast(true)
        setClassForToast("error")
        setMessage(error?.response?.data.message ? `${error?.response?.data.message}, Retry Attempts Remaining: ${error?.response?.data.retryAttemptsRemaining}` : "Invalid pin")
    } finally {
        setLoading(false);
    }
};
const handleToastClose = () => {
    setOpenToast(false)
    setMessage("")
    setClassForToast("")
};
useEffect(() =>{
    if(openToast && classForToast == "success"){
        setTimeout(() =>{
            handleClose()
        },1000)
    }
},[openToast])
    return (
       <>
         <Modal
            open={overRideDeviceModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal overRideModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{heading}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle addDeviceMiddle overrideMiddle">
                    <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                        <p className="clickVerify">click on verify to check the code</p>
                        <div className="verifyCode_wrapper">
                            <p>Verification Code</p>
                            <div className="">
                            <input
                                type="text"
                                value={pin} 
                                onChange={(e) => setPin(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault(); // Prevent the default form submission behavior
                                      handleVerify();     // Trigger the verification function
                                    }
                                  }}
                                className="textBox"
                                placeholder="xxxxxx"
                            />
                             <Button variant="contained" className={`verifyBtn ${classForToast} `}   onClick={handleVerify}>
                                Verify
                            </Button>
                            </div>
                        </div>
                    </Box>
                </div>
           
                <div className="Modal_footer adddeviceFooter">
                    <Box sx={{ display: 'flex' }}>
                        {/* <button
                            className="saveBtn"
                            onClick={handleClose}
                            disabled={!successVerified}
                        >
                        Next
                        </button> */}

                        {/* {loading && (
                            <div className="loader-overlay">
                                <CircularProgress size={50} />
                            </div>
                        )} */}
                    </Box>
                </div>
            </Box>
        </Modal>
        <Snackbar
            open={openToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            // autoHideDuration={5000}
            message={message}
            onClose={handleToastClose}
            className={classForToast == "error" ? "error" :"success"}
        />
       </>
    );
};

export default OverrideDevice;
