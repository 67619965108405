import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
// import ErrorOutline from '@mui/icons-material/ErrorOutline';
// import axios from "axios";
// import errorSign from '../../assets/images/errorSign.svg';
// import profilePic from '../../assets/images/profilePic.svg';
// import circleOk from '../../assets/images/circleOk.svg'
// import checkImg from "../../assets/images/check_circle.svg";
// import Pencil from "../../assets/images/Pencil.svg";
import { useNavigate, Navigate } from "react-router-dom";
// import { LoginValue } from "../../Actions/LoginPageAction";
// import UpdateProfilePhoto from './UpdateProfilePhoto.js';
// import { jwtDecode } from "jwt-decode";
import MyProfile from './MyProfile/MyProfile'
import UpdatePassword from './UpdatePassword/UpdatePassword'
import Preference from './Preference/Preference'
import './profile.scss'

const Profile = () => {
    const [activeScreen, setActiveScreen] = useState('profile')
    // const [formData, setFormData] = useState({ newPassword: '', confirmNewPassword: '' });
    // const [showNewPassword, setShowNewPassword] = useState(false);
    // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    // const [formError, setFormError] = useState("");
    // const [showError, setShowError] = useState(false);
    // const [passwordStrength, setPasswordStrength] = useState({ length: false, mix: false, special: false });
    // const [passwordsMatch, setPasswordsMatch] = useState(true);  // Default to true, as we don't validate until "Save"
    // const [successMessage, setSuccessMessage] = useState(false);
    // const [open, setOpen] = useState(false);
    // const [profileImage, setProfileImage] = useState(profilePic);
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    return (
        <>

            <div className="profileWrapper">
                <div className="main_heading">
                    <h1>My Account</h1>
                </div>
                <div className="menu_bar">
                    <ul>
                        <li>
                            <button
                                className={activeScreen === 'profile' ? 'active' : ''}
                                onClick={() => setActiveScreen('profile')}
                            >
                                Profile
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'password' ? 'active' : ''}
                                onClick={() => setActiveScreen('password')}
                            >
                                Password
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'preference' ? 'active' : ''}
                                onClick={() => setActiveScreen('preference')}
                            >
                                Notification &#38; Preferences
                </button>
                        </li>
                    </ul>
                </div>

                <div className={`userPassdDetail_wrapper ${activeScreen == "profile" ? "myprofileMedia" : activeScreen == "password" ? "mypasswordMedia" : "myprefrenceMedia"}`}>
                    {activeScreen == "profile" ? <MyProfile /> : activeScreen == "password" ? <UpdatePassword /> : <Preference />}
                </div>
                {/* <UpdateProfilePhoto
                    open={open}
                    setOpen={setOpen}
                    fetchImage={fetchImage}
                /> */}
            </div>

        </>
    );
};

export default Profile;
