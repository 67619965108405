const initialState = {
    enableSnackbar: false, // Initial state
  };
  
  // Reducer
  const SnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
      case "TOGGLE_SNACKBAR":
        return {
          ...state,
          enableSnackbar: action.payload,
        };
      default:
        return state;
    }
  };
  
  // Action Creator
  export const toggleSnackbar = (payload) => ({
    type: "TOGGLE_SNACKBAR",
    payload,
  });
  
  export default SnackbarReducer;
  