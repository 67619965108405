import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { jwtDecode } from "jwt-decode";
import BackupCodesDialog from "./BackupCodesDialog"; // Import your BackupCodesDialog component
import "./MFADialog.scss";

const AuthenticatorAppDialog = ({ barcode, isFirstLogin }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showBackupCodesDialog, setShowBackupCodesDialog] = useState(false);
  const [callBackupCodes, setCallBackupCodes] = useState(false);

  const handleVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(!code ? "Please enter the verification code." : "Please enter a 6-digit code.");
      return;
    }

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/verify`, {
        email: decodedToken.email,
        code,
      });

      if (response.data.verifyCode) {
        setShowBackupCodesDialog(true);
        setCallBackupCodes(true);
      } else {
        setErrorMessage("Wrong verification code.");
      }
    } catch (error) {
      console.error("Failed to verify code:", error);
      setErrorMessage("An error occurred during verification. Please try again.");
    }
  };

  return (
    <>
      {!showBackupCodesDialog ?
        <div className="dialog-content emailDailog_wrapper barcodeScreen">
          <p>
            Scan this QR code below with your authentication app. It will generate
            a 6-digit code to verify your account.
          </p>
          <img
            src={barcode}
            alt="QR Code"
            style={{
              display: "block",
              margin: "0 auto",
              width: "211px",
              height: "216px",
              border: "1px solid #D9D9D9",
            }}
          />
          <div style={{ display: "inline-grid", marginTop: "30px" }}>
            <label htmlFor="verification-code">
              Enter the 6-Digit Verification Code
            </label>
            <input
              type="text"
              id="verification-code"
              placeholder="XXXXXX"
              maxLength="6"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setErrorMessage("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleVerification();
                }
              }}
              style={{
                padding: "10px",
                marginTop: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "274px",
              }}
            />
          </div>
          {errorMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
          )}
          <div className="mfa-save">
            <Button onClick={handleVerification} variant="contained" color="primary" className="next-button">
              Next
            </Button>
          </div>
        </div>
        :
        isFirstLogin && <BackupCodesDialog open={callBackupCodes} />
      }
    </>
  );
};

export default AuthenticatorAppDialog;
