import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch } from "react-redux";
import "./MFALogin.css";

const BackupLogin = ({ onClose, open }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [enableNextBtn, setEnableNextBtn] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [code, setCode] = useState("");
    const dispatch = useDispatch();
    const verifyButtonRef = useRef(null);

    const handleBackupCodeVerification = async () => {
        if (!code || code.length !== 6) {
            setErrorMessage(
                !code
                    ? "Please enter the verification code."
                    : "Please enter a 6-digit code."
            );
            return;
        }

        const token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        const userGuid = decodedToken.userGuid;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verifyViaBackupCodeByUserId`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userGuid,
                    code: code,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (data.verifyCode) {
                setMessage("Backup code verification successful!");
                setErrorMessage("");
                setEnableNextBtn(true);
            } else {
                setErrorMessage("Backup code verification failed. Please try again.");
            }
        } catch (error) {
            console.error("Failed to verify backup code:", error);
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    const handleNext = () => {
        setShowSuccessDialog(true);
        console.log(showSuccessDialog)
    }

    useEffect(() => {
        if (showSuccessDialog) {
            console.log("Success Dialog should now be open");
        }
    }, [showSuccessDialog]);

    const handleDone = () => {
        localStorage.setItem("isMFACompleted", "true");
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("lastPath", "/floors");
        dispatch(LoginValue(true));
    };

    const handleCloseSuccessDialog = () => {
        setShowSuccessDialog(false);
        onClose();
    };

    return (
        <>
            {!showSuccessDialog ?
                <>
                    <div className="dialog-content emailDailog_wrapper">
                        <p>Let’s set up your account via backup code.</p>
                        <div className="authenticator-input">
                            <div className="label-text">Backup Code</div>
                            <div className="align">
                                <input
                                    type="text"
                                    className="text-box"
                                    value={code}
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                        setErrorMessage("");
                                        setMessage("");
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            verifyButtonRef.current.focus();
                                            handleBackupCodeVerification();
                                        }
                                    }}
                                    maxLength="6"
                                />
                                <div className="send-code-button">
                                    <Button
                                        color="primary"
                                        className="btn-text"
                                        ref={verifyButtonRef}
                                        onClick={handleBackupCodeVerification}
                                    >
                                        Verify
                                    </Button>
                                </div>
                            </div>
                            {message && (
                                <div style={{ color: "green", marginTop: "20px" }}>{message}</div>
                            )}
                            {errorMessage && <div style={{ color: "red", marginTop: "20px", width: "max-content" }}>{errorMessage}</div>}
                        </div>
                    </div>
                    <div className="mfa-save">
                        <Button
                            color="primary"
                            className="disabled-button"
                            disabled={!enableNextBtn}
                            onClick={() => handleNext()}
                        >
                            Next
                        </Button>
                    </div>
                </>
                :
                <div className="succes_modal">
                    <img src={checkImg} alt="Success" />
                    <h2>
                        Success
                    </h2>
                    <p>
                        Your verification is successfully completed.
                    </p>
                    <div className="mfa-save">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDone}
                    >
                        Done
                    </Button>
                    </div>
                </div>
            }
        </>

    );
};

export default BackupLogin;
