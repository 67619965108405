import React, { useState, useEffect } from 'react';
import './style.scss';
import Slider from '@mui/material/Slider';

const Footer = ({ floorMapRef, currentFloor, expand, isDynamicFooter }) => {

    const [zoomLevel, setZoomLevel] = useState(100);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        setZoomLevel(100);
    }, [currentFloor]);

    useEffect(() => {
        if (floorMapRef && floorMapRef.current) {
            floorMapRef.current.style.transform = `scale(${zoomLevel / 100})`;
            floorMapRef.current.style.transformOrigin = '0 0';
        }
    }, [zoomLevel, floorMapRef]);

    const handleZoomIn = (event) => {

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const scrollX = window.scrollX;
        const scrollY = window.scrollY;

        setZoomLevel((prev) => Math.min(prev + 10, 200));

        // Restore scroll position
        window.scrollTo(scrollX, scrollY);
    };

    const handleZoomOut = (event) => {

        if (event && event.preventDefault) {
            event.preventDefault(); 
        }
        const scrollX = window.scrollX;
        const scrollY = window.scrollY;

        setZoomLevel((prev) => Math.max(prev - 10, 50));

        window.scrollTo(scrollX, scrollY);
    };

    const handleResetZoom = () => {
        setZoomLevel(100);
    };

    const handleFullScreen = () => {
        if (floorMapRef && floorMapRef.current) {
            const element = floorMapRef.current;

            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen(); // Safari
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen(); // IE/Edge
            }

            setIsFullScreen(true);

            document.addEventListener('fullscreenchange', handleExitFullScreen);
            document.addEventListener('keydown', handleEscapeKey);
        }
    };

    const handleExitFullScreen = () => {
        if (!document.fullscreenElement) {
            setIsFullScreen(false);
            document.removeEventListener('fullscreenchange', handleExitFullScreen);
            document.removeEventListener('keydown', handleEscapeKey);
        }
    };

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            document.exitFullscreen();
        }
    };

    const handleSliderChange = (event) => {
        setZoomLevel(Number(event.target.value));
    };

    if (isDynamicFooter && expand) {
        // Dynamic Footer (expanded state)
        return (
            <div className="footer_wrapper" style={{justifyContent: "center"}}>
                <ul className="leftMenu" style={{display: "none"}}>
                    <li><a className="map" href="#"></a></li>
                    <li><a className="list" href="#;"></a></li>
                </ul>
                <ul className="centerMenu">
                    <li><a className="strobe-Button" href="#"></a><span>Strobe</span></li>
                    <li><a className="Push-Button" href="#"></a><span>Push Button</span></li>
                    <li><a className="Speaker-Button" href="#"></a><span>Speaker</span></li>
                    <li><a className="Camera-Button" href="#"></a><span>Camera</span></li>
                    <li><a className="repair-Button" href="#"></a><span>Need Repair</span></li>
                </ul>
                <ul className="rightMenu" style={{ display: "none" }}>
                    <div>Zoom: {zoomLevel}%</div>
                    <li><a className="zoom_out" href="#" onClick={handleZoomOut}></a></li>
                    <li style={{ position: 'relative' }}>
                        <Slider
                            size="small"
                            value={zoomLevel}
                            min={50}
                            max={200}
                            step={10}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            aria-label="Zoom Slider"
                            style={{ width: "110px", height: "5px", marginLeft: "5px" }}
                        />
                    </li>
                    <li><a className="zoom_in" href="#" onClick={handleZoomIn}></a></li>
                    <li><a className="fullscreen" href="#" onClick={handleFullScreen}></a></li>
                    <li>
                        <button
                            onClick={handleResetZoom}
                            className="reset-zoom"
                            style={{ backgroundColor: "#345BA9", marginLeft: "10px", fontSize: "10px", fontWeight: 600, borderRadius: "6px" }}
                        >
                            Reset Zoom
                        </button>
                    </li>
                </ul>
            </div>
        );
    }

    return (
        <div
            className="footer_wrapper"
            onWheel={(event) => {
                if (event.deltaY < 0) {
                    handleZoomIn();
                } else if (event.deltaY > 0) {
                    handleZoomOut();
                }
            }}
            tabIndex="0"
            onKeyDown={(event) => {
                if (event.key === '+') {
                    handleZoomIn();
                } else if (event.key === '-') {
                    handleZoomOut();
                }
            }}
            style={{ outline: 'none', justifyContent: 'space-between' }}
        >
            <ul className="leftMenu">
                <li><a className="map" href="#"></a></li>
                <li><a className="list" href="#"></a></li>
            </ul>

            <ul className="centerMenu">
                <li><a className="strobe-Button" href="#"></a><span>Strobe</span></li>
                <li><a className="Push-Button" href="#"></a><span>Push Button</span></li>
                <li><a className="Speaker-Button" href="#"></a><span>Speaker</span></li>
                <li><a className="Camera-Button" href="#"></a><span>Camera</span></li>
                <li><a className="repair-Button" href="#"></a><span>Need Repair</span></li>
            </ul>

            <ul className="rightMenu">
                <div>Zoom: {zoomLevel}%</div>
                <li><a className="zoom_out" href="#" onClick={handleZoomOut}></a></li>
                <li style={{ position: 'relative' }}>
                    <Slider
                        size="small"
                        value={zoomLevel}
                        min={50}
                        max={200}
                        step={10}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}%`}
                        aria-label="Zoom Slider"
                        style={{ width: "110px", height: "5px", marginLeft: "5px" }}
                    />
                </li>
                <li><a className="zoom_in" href="#" onClick={handleZoomIn}></a></li>
                <li><a className="fullscreen" href="#" onClick={handleFullScreen}></a></li>
                <li>
                    <button
                        onClick={handleResetZoom}
                        className="reset-zoom"
                        style={{ backgroundColor: "#345BA9", marginLeft: "10px", fontSize: "10px", fontWeight: 600, borderRadius: "6px" }}
                    >
                        Reset Zoom
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Footer;
