import React, { useState, useEffect } from "react";
import { Box, FormControl, Button, NativeSelect, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { prefrenceData } from "./FieldJson";
import CustomizeAudio from "./CustomizeAudio";
import "../MediaSettings/MediaSettings.scss";

const MediaSettings = () => {
  const [formData, setFormData] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [savedData, setSavedData] = useState({});

  useEffect(() => {
    const fetchGlobalSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`
        );
        if (response.status === 200) {
          const globalSettings = response.data.allGlobalSettings;

          const updatedFormData = {};
          prefrenceData.forEach((field) => {
            const matchingSetting = globalSettings.find(
              (setting) => setting.code === field.name
            );
            if (matchingSetting) {
              updatedFormData[field.name] = matchingSetting.value;
            }  else {
              if (field.name === "flashCount" || field.name === "speakerCount") {
                updatedFormData[field.name] = "3";
              }
            }
          });

          setFormData(updatedFormData);
          setSavedData(updatedFormData);
        }
      } catch (error) {
        console.error("Error fetching global settings:", error);
      }
    };

    fetchGlobalSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      setIsSaveEnabled(Object.values(updatedData).some((val) => val !== ""));
      return updatedData;
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = Object.entries(formData).map(([key, value]) => ({
      code: key,
      value: value,
    }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/updateGlobalSettingCode`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessSnackbar(true);
        setIsSaveEnabled(false);
        setSavedData(formData);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <div className="media_control_wrapper">
      <div className="media_wrapper">       
        <div className="dropdown-wrapper">
          <Box component="form" noValidate autoComplete="off">
            {prefrenceData.map((preference) => (
              <FormControl
                fullWidth
                className="innerFields"
                key={preference.id}
                style={{
                  marginTop: "3%",
                  width: "450px",
                }}
              >
                <div
                  variant="standard"
                  htmlFor={preference.id}
                  className="sub-heading"
                >
                  {preference.label}
                </div>
                <NativeSelect
                  id={preference.id}
                  name={preference.name}
                  value={formData[preference.name] || ""}
                  onChange={handleChange}
                  style={{
                    border: "1px solid #4E4E4E",
                    borderRadius: "6px",
                    fontFamily: "Barlow Semi Condensed",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.0275em",
                    textAlign: "left",
                    bottom: "10px",
                    position: "relative",
                  }}
                  className="custon-select"
                  disableUnderline="true"
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {preference.data.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            ))}
          </Box>
        </div>
        <div className="btn-wrapper">
          {savedData.flashCount && savedData.speakerCount && (
            <div className="action-summary">
              The light will flash {savedData.flashCount} times, and the sound
              will play {savedData.speakerCount} times.
            </div>
          )}
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!isSaveEnabled}
            className="save"
          >
            Save
          </Button>
        </div>
      </div>
      <CustomizeAudio />
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Setting Saved Successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MediaSettings;
