import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import axios from "axios";
import { LoginValue } from "../../Actions/LoginPageAction";
import { Validation } from '../../Validation/Validation';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import errorSign from '../../assets/images/errorSign.svg';
import './style.scss';
import MFADialog from "./MFADialog";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const SigninPage = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [formError, setFormError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false); // Make sure to define this state
  const [showMFADialog, setShowMFADialog] = useState(false);
  const [closeModalValidation, setCloseModalValidation] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValidation = () => {
    let isValid = true;

    if (Validation.empty(formData.username)) {
      setFormError("Please enter your Username");
      isValid = false;
    }
    if (Validation.empty(formData.password)) {
      setFormError("Please enter your Password");
      isValid = false;
    }
    setShowError(true);
    return isValid;
  };

  const getDeviceId = async () => {
    // Initialize the agent
    const fp = await FingerprintJS.load();
    // Get the visitor identifier
    const result = await fp.get();
    const deviceId = result.visitorId;
    console.log("Device ID:", deviceId);
    return deviceId;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) return;

    const deviceId = await getDeviceId();

    try {
      const encodedPassword = btoa(formData.password);
      const formDataToSend = {
        email: formData.username,
        password: formData.password,
        deviceId
      };

      const url = `${process.env.REACT_APP_API_URL}/users/authenticate`;
      const response = await axios({
        method: 'POST',
        url: url,
        data: formDataToSend,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        const token = response.data?.token;
        const decodedToken = jwtDecode(token);
        if (token) {
          localStorage.setItem("token", JSON.stringify(response.data?.token));
          setFormData({ username: '', password: '' }); // Clear form
          setShowError(false);
          setFormError("");
          if (response.data?.user.rememberDeviceCheck) {
            localStorage.setItem("isMFACompleted", "true");
            // Mark user as authenticated
            localStorage.setItem("isAuthenticated", "true");
            // Save the default path
            localStorage.setItem("lastPath", "/floors");
            dispatch(LoginValue(true));
          }
          else {
            if (decodedToken.mfa_enabled == "1") {
              // navigate("/mfa-options", {
              //  state: { password: formData.password },
              // });
              setShowMFADialog(true);
              setCloseModalValidation(true);
            } else if (decodedToken.mfa_enabled == "0") {
              // Mark MFA as completed
              localStorage.setItem("isMFACompleted", "true");
              // Mark user as authenticated
              localStorage.setItem("isAuthenticated", "true");
              // Save the default path
              localStorage.setItem("lastPath", "/floors");
              dispatch(LoginValue(true));
            }
          }
        }
      } else if (response.status === 400) {
        setFormError(response.message);
        setShowError(true);
      }
    } catch (error) {
      console.error("Error making request:", error);
      setFormError("Invalid credentials");
      setShowError(true);
    }
  };

  const handleClose = () => {
    setShowMFADialog(false);
    setCloseModalValidation(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
        setFormError("");
      }, 5000);
    }
  }, [showError]);

  // Key press handler to submit on "Enter" key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <>
      {(showError && formError !== "") && (
        <div className="error_wraper">
          <img src={errorSign} alt="Error" />
          {formError}
        </div>
      )}
      <div className="right-section">
        <div className="login-form">
          <h2>Welcome</h2>
          <form className="field" onSubmit={handleSubmit}>
            <Box component="form" noValidate autoComplete="off" className="field">
              <TextField
                id="username"
                name="username"
                label="Email/Username"
                variant="outlined"
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                value={formData.username}
                error={!!formError && showError}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    transform: 'inherit',
                  },
                }}
                InputProps={{
                  style: { borderRadius: '4px' },
                }}
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                value={formData.password}
                error={!!formError && showError}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    transform: 'inherit',
                  },
                }}
                InputProps={{
                  style: { borderRadius: '4px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Link to="/ForgotPassword" className="forgot-password">
              Forgot Password?
            </Link>
            <button type="submit" className="btnSubmit">Sign in</button>
          </form>
          <MFADialog
            open={showMFADialog}
            onClose={handleClose}
            closeModalValidation={closeModalValidation}
            setCloseModalValidation={setCloseModalValidation}
          />
        </div>
      </div>
    </>
  );
};

export default SigninPage;
