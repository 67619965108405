import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch } from "react-redux";

const BackupCodesDialog = ({ open, onClose }) => {
    const [backupCodes, setBackupCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        const userGuid = decodedToken.userGuid;

        const fetchBackupCodes = async () => {
            setLoading(true);
            setError("");
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/generateBackupCode`,
                    { userGuid },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (err) {
                if (err.response && err.response.status === 409) {
                    console.warn("Backup codes already exist. Fetching existing codes.");
                } else {
                    console.error("Error generating backup codes:", err);
                    setError("An error occurred while generating backup codes. Please try again.");
                    setLoading(false);
                    return; // Exit the function if another error occurred
                }
            }

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/listOfCodeViaUserGuid`,
                    { userGuid },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const codes = response.data.codes?.map((item) => item.code) || [];
                setBackupCodes(codes);
            } catch (err) {
                console.error("Error fetching backup codes:", err);
                setError("An error occurred while generating and fetching backup codes. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchBackupCodes();
        }
    }, [open]);

    const onNext = () => {
        setShowSuccessDialog(true);
    };

    const handleCloseSuccessDialog = () => {
        setShowSuccessDialog(false);
        onClose();
    };

    const handleDone = () => {
        localStorage.setItem("isMFACompleted", "true");
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("lastPath", "/floors");
        dispatch(LoginValue(true));
    };

    return (
        <>
            {!showSuccessDialog ?
                <>
                    <div className="backup-content">
                        {loading ? (
                            <p>Loading backup codes...</p>
                        ) : error ? (
                            <p style={{ color: "red" }}>{error}</p>
                        ) : backupCodes.length > 0 ? (
                            <div>
                                <h2 className="tagLine">Save your backup codes</h2>
                                <p style={{
                                    fontFamily: "Barlow Semi Condensed",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "23px",
                                    marginBottom: "40px"
                                }}>Keep this backup code somewhere safe but accessible:</p>
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", width: "243px", height: "183px", justifySelf: "center" }}>
                                    {backupCodes.map((code, index) => (
                                        <div key={index} style={{
                                            textAlign: "center", fontFamily: "Barlow Semi Condensed",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "23px"
                                        }}>
                                            {code}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>No backup codes available. Please try generating them again.</p>
                        )}
                    </div>
                    <div className="mfa-save">
                        <Button
                            onClick={onNext}
                            variant="contained"
                            color="primary"
                            disabled={loading || error}
                        >
                            Next
                        </Button>
                    </div>
                </>
                :
                <div className="succes_modal">
                    <img src={checkImg} alt="Success"/>
                    <h2>
                        Success
                    </h2>
                    <p>
                        Your verification is successfully completed.
                    </p>
                    <div className="mfa-save">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDone}
                    >
                        Done
                    </Button>
                    </div>
                </div>}
        </>
    );
};

export default BackupCodesDialog;
