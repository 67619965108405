import React, { useState, useRef } from 'react';
import { TextField, Button, Box, Modal } from '@mui/material';
import axios from 'axios';
import Cropper from 'react-easy-crop'; // Cropping library
import { getCroppedImg, rotateImage } from './cropUtils'; // Utility functions for cropping and rotating
import warning from '../../../assets/images/Warning.svg';
import checkImg from '../../../assets/images/check_circle.svg';
import closeIcon from '../../../assets/images/closeicon.svg';
import uploadFile from '../../../assets/images/uploadFile.svg';
import Camera from '../../../assets/images/Camera.png'; // Add a camera icon for the new button
import { jwtDecode } from "jwt-decode";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    padding: 4,
};

const UpdateProfilePhoto = ({ setOpen, open, fetchImage }) => {
    const [formError, setFormError] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0); // For rotating the image
    const [croppedArea, setCroppedArea] = useState(null);
    const [cameraMode, setCameraMode] = useState(false);
    const [cameraStream, setCameraStream] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);

    const videoRef = useRef(null);

    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const fileTypes = ['image/jpeg', 'image/png'];
        const maxSize = 1 * 1024 * 1024;
        
        if (file) {
            if (!fileTypes.includes(file.type)) {
                setFormError('The selected file format is not supported.');
                return;
            }
            if (file.size > maxSize) {
                setFormError('File size too large. Max 1MB.');
                return;
            }
            setUploadedFile(file);
            setImagePreview(URL.createObjectURL(file));
            setFormError("");
        }
    };

    // Capture photo from the camera
    const handleCapturePhoto = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0);
        canvas.toBlob(blob => {
            const imageUrl = URL.createObjectURL(blob);
            setUploadedFile(blob);
            setImagePreview(imageUrl);
            stopCamera();
        });
    };

    // Access the camera
    const handleOpenCamera = async () => {
        setCameraMode(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            setCameraStream(stream);
        } catch (err) {
            setFormError('Camera access denied.');
        }
    };

    // Stop camera stream
    const stopCamera = () => {
        if (cameraStream) {
            cameraStream.getTracks().forEach(track => track.stop());
            setCameraStream(null);
        }
        setCameraMode(false);
    };

    // Cropping functionality
    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    // Rotate the image
    const handleRotate = async (angle) => {
        const rotatedImage = await rotateImage(imagePreview, rotation + angle);
        setImagePreview(rotatedImage);
        setRotation(rotation + angle); // Update the rotation angle
    };

    // Save the cropped image
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!uploadedFile) {
            setFormError('Please upload or capture an image.');
            return;
        }

        const token = JSON.parse(localStorage.getItem('token'));
        const croppedImage = await getCroppedImg(imagePreview, croppedArea, rotation);
        const decodedToken = jwtDecode(token);
        const formData = new FormData();
        formData.append('image', croppedImage, 'cropped-image.jpeg');

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/image/upload/${decodedToken.userGuid}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201 || response.status === 200) {
                setSuccessMessage(true); // Show success message on success
                fetchImage()
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setFormError('Error uploading the image. Please try again.');
        }
    };

    // Close modal and reset states
    const handleClose = () => {
        setOpen(false);
        setUploadedFile(null);
        setImagePreview(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setRotation(0);
        setCroppedArea(null);
        setCameraMode(false);
        setSuccessMessage(false);
        setFormError('');
        if (cameraStream) stopCamera();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="upload-photo-modal"
            className="profileModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>Upload Photo</h1>
                    <button onClick={handleClose} className="close_button">
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>

                {!successMessage ? (
                    <>
                        <div className="Modal_middle uploadMediaContainer">
                            {!cameraMode && (
                                <>
                                    {!imagePreview && (
                                        <>
                                            <div className='uploadFileMiddle'>
                                                <TextField
                                                    className="innerFields attachmentField"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <Button variant="contained" component="label" className="attachmentBtn">
                                                                <img src={uploadFile} alt="Upload" />
                                                                <input type="file" hidden accept="image/*" onChange={handleFileUpload} />
                                                            </Button>
                                                        ),
                                                    }}
                                                />
                                                <p className="inst">Browse from your computer</p>
                                                <p className="type">(File type: .JPEG or .PNG</p>
                                                <p className="type">Max Size: 1MB)</p>

                                            </div>

                                            <div className='uploadFileMiddle'>
                                                <TextField
                                                    className="innerFields attachmentField"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <Button variant="contained" component="label" className="attachmentBtn" onClick={handleOpenCamera}>
                                                                <img src={Camera} alt="Camera" />
                                                            </Button>
                                                        ),
                                                    }}
                                                />
                                                <p className="inst">Camera</p>
                                                <p className="inst">Live capture by Camera</p>
                                            </div>
                                        </>
                                    )}
                                    {imagePreview && (
                                        <div className="image-preview-container">
                                            <Cropper
                                                image={imagePreview}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={1} // Aspect ratio 1:1 for square crop
                                                onCropChange={setCrop}
                                                onZoomChange={setZoom}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                            {cameraMode && (
                                <div>
                                    <video ref={videoRef} autoPlay playsInline style={{ width: '100%' }} />
                                    <Button variant="contained" onClick={handleCapturePhoto}>
                                        Capture Photo
                                    </Button>
                                </div>
                            )}
                            {formError && <p className="errorMessage"><img src={warning} alt="Warning" />{formError}</p>}
                        </div>

                        {/* {imagePreview && (
                            <div className="rotate-controls">
                                <Button onClick={() => handleRotate(-90)}>Rotate Left</Button>
                                <Button onClick={() => handleRotate(90)}>Rotate Right</Button>
                            </div>
                        )} */}

                        <div className="Modal_footer">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSubmit}>
                                    Save
                                </Button>
                            </Box>
                        </div>
                    </>
                ) : (
                    <div className="sucssess_Box">
                        <img src={checkImg} alt="Success" />
                        <h2>Success</h2>
                        <p>Profile photo updated successfully.</p>
                        <Button onClick={handleClose} variant="contained">Close</Button>
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default UpdateProfilePhoto;
