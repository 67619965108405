import React, { useState, useEffect } from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import checkImg from "../../assets/images/check_circle.svg";
import errorSign from "../../assets/images/errorSign.svg"
import './style.scss';

const ForgotPassword = () => {
    const [formData, setFormData] = useState({ email: '' });  // Single state object for both fields
    const [formError, setFormError] = useState("");  // Single state object for errors
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    // Generalized input change handler
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = {
                email: formData.email,
            };
            const url = `${process.env.REACT_APP_API_URL}/forgotPassword`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json'  // Optional: Ensure content type is JSON
                }
            });

            if (response.status === 200) {
                setSuccessMessage(true);
                setFormData({ email: '' });  // Clear form
                setShowError(false)
                setFormError("")
            }
            //  else if (response.status === 400) {
            //     setFormError(response.message);

            // }
        } catch (error) {
            console.error("Error making request:", error);
            setFormError(error.response.data.message);
            setShowError(true);
            setSuccessMessage(false);
        }
    };
    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
                setFormError("")
            }, 5000);
        }
    }, [showError]);
    return (
        <>
            {showError && <div className="error_wraper"><img src={errorSign} alt="img" />{formError}</div>}
            <div className="right-section forgotsection">
                {!successMessage ?
                    <div className="login-form forgotError">
                        <h2>Forgot your password</h2>
                        <p className="paragraphForgot">Please enter the email entered while creating the account, and we’ll send you a link to reset the password.</p>
                        <form className="field" onSubmit={handleSubmit}>
                            <Box component="form" noValidate autoComplete="off" className="field">
                                <TextField
                                    id="email"
                                    name="email"  // Match name to state key
                                    label="Email"
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                    error={!!formError.emailError}
                                    helperText={formError.emailError}
                                    InputLabelProps={{
                                        shrink: true,  // Disable floating animation
                                        style: {
                                            transform: 'inherit',
                                        },  // Custom label style
                                    }}
                                    InputProps={{
                                        style: { borderRadius: '4px' },  // Custom input style
                                    }}
                                />
                            </Box>
                            <button type="submit" className="btnSubmit">Send link to email</button>
                            <Link to="/" className="forgot-password">
                                Back to Sign in
                            </Link>
                            {/* <a href="/" rel="noopener noreferrer" className="forgot-password">Back to Sign in</a> */}
                        </form>
                    </div> :
                    <div className="emailsent-wrapper">
                        <img src={checkImg} alt="img" />
                        <h2>Email Sent.</h2>
                        <p>Check your email for a link to reset your password.</p>
                        {/* <span>This link is valid upto 20 minutes.</span> */}
                    </div>
                }

            </div>
        </>
    );
};

export default ForgotPassword;
