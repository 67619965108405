import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Box, NativeSelect, FormControl } from '@mui/material';
import './CameraFeed.scss';
import axios from 'axios';

const CameraFeed = () => {
    const [videoSources, setVideoSources] = useState([]); // Store multiple video sources
    const [floorDetails, setFloorDetails] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState('');

    const handleChange = (event) => {
        setSelectedFloor(event.target.value);
    };

    // Floor Listing API
    const fetchData = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('token'));
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/floor/floorList`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setFloorDetails(response.data);
                if (response.data.length > 0) {
                    setSelectedFloor(response.data[0].id); // Set the first floor by default
                }
            }
        } catch (error) {
            console.error('Error fetching floor data:', error);
        }
    };

    // Fetch video sources
    const fetchVideoData = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('token'));
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/device/data/floor/${selectedFloor}/type/1`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setVideoSources(response.data);
            }
        } catch (error) {
            console.error('Error fetching video data:', error);
        }
    };

    // Initialize all video.js players
    const initializePlayers = () => {
        videoSources.forEach((source) => {
            const videoPlayerElement = document.getElementById(`video-player-${source.id}`);
            if (videoPlayerElement) {
                const player = videojs(videoPlayerElement, {
                    autoplay: true,
                    muted: true,
                    playsinline: true, // Enable inline playback for mobile
                    controls: true,
                    controlBar: {
                        pipToggle: false,
                        fullscreenToggle: true,
                        volumePanel: false,
                        playToggle: false,
                        progressControl: false,
                        remainingTimeDisplay: false,
                        currentTimeDisplay: false,
                        timeDivider: false,
                        playbackRateMenuButton: false,
                        textTrackSettings:false,
                    },
                    preload: 'auto',
                    sources: [
                        {
                            src: source.streamUrl,
                            type: 'application/x-mpegURL',
                        },
                    ],
                });

                player.on('error', (error) => {
                    console.error(`Video.js player error for player ${source.id}:`, error);
                });
            }
        });
    };

    // Cleanup players when component unmounts or video sources change
    useEffect(() => {
        fetchData(); // Fetch floor details on mount
    }, []);

    useEffect(() => {
        if (selectedFloor) {
            fetchVideoData(); // Fetch video sources when floor changes
        }
    }, [selectedFloor]);

    useEffect(() => {
        if (videoSources.length > 0) {
            initializePlayers();

            // Cleanup video.js players before reinitializing
            return () => {
                videoSources.forEach((source) => {
                    const player = videojs.getPlayer(`video-player-${source.id}`);
                    if (player) {
                        player.dispose();
                    }
                });
            };
        }
    }, [videoSources]);

    return (
        <div className="deviceMangement_wrapper">
            <div className="main_heading cameraFeed_head">
                <h1>Camera Feed</h1>
            </div>
            <div className="AddFloor camera_feed_floor">
                <FormControl fullWidth className="floor-dropdown">
                    <label className="floorLabel">Floor</label>
                    <NativeSelect onChange={(e) => handleChange(e)}>
                        {Array.isArray(floorDetails) &&
                            floorDetails.map((floor) => (
                                <option key={floor.id} value={floor.id}>
                                    {floor.floorName}
                                </option>
                            ))}
                    </NativeSelect>
                </FormControl>
            </div>
            <div className="video-container">
                {videoSources.map((source) => (
                    <div className="videoPlaer_wrapper" key={source.id}>
                        <video
                            id={`video-player-${source.id}`}
                            className="video-player video-js vjs-default-skin"
                            height="235"
                            controls
                            autoPlay
                            muted
                            playsInline
                        >
                            Your browser does not support the video tag.
                        </video>
                        <p className="camera_details">{source.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CameraFeed;
