import React, { useState } from "react";
import "./style.scss";
import logo from "../../assets/images/logo.svg";
import home_work from "../../assets/images/home_work.svg";
import crisis_alert from "../../assets/images/crisis_alert.svg";
import healthStauts from "../../assets/images/healthStauts.svg";
import directions_run from "../../assets/images/directions_run.svg";
import dark_mode from "../../assets/images/dark_mode.svg";
import home_workMobile from "../../assets/images/home_workMobile.svg";
import crisis_alertMobile from "../../assets/images/crisis_alertMobile.svg";
import directions_runMobile from "../../assets/images/directions_runMobile.svg";
import dark_modeMobile from "../../assets/images/dark_modeMobile.svg";
import account_circle from "../../assets/images/account_circle.svg";
import threeDots from "../../assets/images/threeDots.svg";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDevicesInNewWindow = () => {
    window.open("/devices", "_self", "noopener,noreferrer");
  };
  return (
    <div className="header_wrapper">
      <div className="logo">
        <Link to="/dashboard">
          <img alt="Logo" src={logo} />
        </Link>
      </div>
      <div className="navbar forDesktop">
        <nav>
          <ul>
            <li>
              <Link to="/healthStatus" title="Health Status">
                <img alt="img" src={healthStauts} />
              </Link>
            </li>
            <li>
              <Link to="/" title="Home">
                <img alt="img" src={home_work} />
              </Link>
            </li>
            <li>
              {/* <Link to="#"> <img alt="img" src={crisis_alert} /></Link> */}
              <NavLink to="/devices" activeClassName="active" title="Devices">
                <img alt="img" src={crisis_alert} />
              </NavLink>
            </li>
            <li>
              <Link to="/globalSettings" title="Global Settings">
                <img alt="img" src={dark_mode} />
              </Link>
            </li>
            <li>
              <Link to="/profile" title="Profile">
                <img alt="img" src={account_circle} />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="navbar forMobile">
        <nav className="navMobile">
          <ul className="profileMobile">
            <li>
              <Link to="/profile">
                <img alt="img" src={account_circle} />
              </Link>
            </li>
          </ul>

          <div className="mobilethreeDotsbtn">
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className="threeDotsBtn"
            >
              <img src={threeDots} alt="img" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link to="/">
                  <img alt="img" src={home_workMobile} />
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="/healthStatus">
                  <img alt="img" src={healthStauts} />
                </Link>
              </MenuItem >
              <MenuItem onClick={handleClose}> 
                <NavLink to="/devices" activeClassName="active">
                  <img alt="img" src={crisis_alertMobile} />
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}> 
                <Link to="#">
                  <img alt="img" src={directions_runMobile} />
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}> 
                <Link to="/globalSettings">
                  <img alt="img" src={dark_modeMobile} />
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
