import React, { useState } from "react";
import {
  Box,
  Modal,
} from '@mui/material';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import mfa_call from "../../assets/images/mfa_call.png";
import mfa_mail from "../../assets/images/mfa_mail.png";
import mfa_shield from "../../assets/images/mfa_shield.png";
import "./MFADialog.scss";
import AuthenticatorAppDialog from "./AuthenticatorAppDialog";
import MFALogin from "./MFALogin";
import EmailDialog from "./EmailDialog";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { jwtDecode } from "jwt-decode";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  paddingLeft: 4,
  paddingRight: 4,
};
const MFADialog = ({ open, onClose, setCloseModalValidation, closeModalValidation }) => {
  const [isAuthDialogOpen, setAuthDialogOpen] = useState(false);
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [mfaResponseData, setMfaResponseData] = useState();
  const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [rememberDeviceCheck, setRememberDeviceCheck] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const getDeviceId = async () => {
    // Initialize the agent
    const fp = await FingerprintJS.load();
    // Get the visitor identifier
    const result = await fp.get();
    const deviceId = result.visitorId;
    console.log("Device ID:", deviceId);
    return deviceId;
  };

  const handleMethodSelection = (method) => {
    setSelectedMethod(method);
  };

  const handleNextClick = async () => {
    try {
      const deviceId = await getDeviceId();
      setCloseModalValidation(false);
      setSelectedMethod("");
      // Extract userGuid from token
      const token = JSON.parse(localStorage.getItem("token"));
      const decodedToken = jwtDecode(token);
      const userGuid = decodedToken.userGuid;
      console.log(decodedToken);
      // Prepare payload
      const requestBody = {
        rememberDeviceCheck,
        deviceId,
        userGuid,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/addRememberDevice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {

        if (selectedMethod === "email") {
          await handleEmailClick();
        } else if (selectedMethod === "authenticator") {
          handleAuthenticatorAppClick();
        } else {
          console.warn("No valid verification method selected.");
        }
      } else {
        console.error("Failed to remember device.");
      }
    } catch (error) {
      console.error("Error fetching deviceId or calling API:", error);
    }
  };

  const handleEmailClick = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/sendEmailVerifyCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: decodedToken.email
        }),
      });

      if (response.status === 200) {
        setEmailDialogOpen(true)
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };

  const handleAuthenticatorAppClick = async () => {

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    const userGuid = decodedToken.userGuid;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/authenticate_user_live`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: decodedToken.email,
          userGuid: userGuid,
        }),
      });
      const data = await response.json();
      if (response.status === 200) {
        if (data.mfaSetupStatus === true) {
          setIsFirstLogin(false);
          setLoginDialogOpen(true); // Open login dialog
        } else if (data.mfaSetupStatus === false) {
          setIsFirstLogin(true);
          setMfaResponseData(data.secretImageUri);
          setAuthDialogOpen(true); // Open Auth app dialog
        } else {
          console.error("Unexpected mfaSetupStatus value:", data.mfaSetupStatus);
        }
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addFlorModal"
    >
      <Box sx={style}>
        <div className="dialog-title">
          <h2 className="dialog-title-text">Multi-Factor Authentication</h2>
          <IconButton onClick={onClose} className="icon-container">
            <CloseIcon className="icon" />
          </IconButton>
        </div>
        {isEmailDialogOpen && !closeModalValidation ? <EmailDialog /> : isAuthDialogOpen && !closeModalValidation ? <AuthenticatorAppDialog barcode={mfaResponseData} isFirstLogin={isFirstLogin} /> : isLoginDialogOpen && !closeModalValidation ? <MFALogin /> :
          <>
            <div className="dialog-content">
              <div className="dialog-space">
                <div className="dialog-description">
                  <p>
                    Prevent hackers from accessing your account with an additional
                    layer of security. When you sign in, 2-Step verification helps
                    ensure your personal information stays private, safe, and
                    secure.
                  </p>
                  <p>Choose the preferred verification method.</p>
                </div>
              </div>
              <Button
                fullWidth
                className="mfa-button"
                disabled
                style={{ border: "1px dashed #5b5d75", marginBottom: "16px" }}
              >
                <div className="button-content">
                  <img src={mfa_call} alt="Call Icon" className="button-image" />
                  <div className="button-text">Verification by Phone/SMS</div>
                </div>
              </Button>

              <Button
                fullWidth
                className={`mfa-button ${selectedMethod === "email" ? "selected" : ""}`}
                style={{
                  border: "1px dashed #5b5d75",
                  marginBottom: "16px",
                  backgroundColor: selectedMethod === "email" ? "#e6f7ff" : "inherit",
                }}
                onClick={() => handleMethodSelection("email")} FFFFf
              >
                <div className="button-content">
                  <img src={mfa_mail} alt="Mail Icon" />
                  <div className="button-text">Verification by Email</div>
                </div>
              </Button>

              <Button
                fullWidth
                className={`mfa-button ${selectedMethod === "authenticator" ? "selected" : ""
                  }`}
                style={{
                  border: "1px dashed #5b5d75",
                  backgroundColor:
                    selectedMethod === "authenticator" ? "#e6f7ff" : "inherit",
                }}
                onClick={() => handleMethodSelection("authenticator")}
              >
                <div className="button-content">
                  <img
                    src={mfa_shield}
                    alt="Authenticator Icon"
                    className="button-image"
                  />
                  <div className="button-text">
                    Verification by Authenticator App
                  </div>
                </div>
              </Button>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="rememberDeviceCheck"
                  checked={rememberDeviceCheck}
                  onChange={(e) => setRememberDeviceCheck(e.target.checked)}
                  className="check"
                />
                <label htmlFor="rememberDeviceCheck" className="rem-text">Remember this device</label>
              </div>
            </div>
            {/* Next Button */}
            <div className="mfa-save">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNextClick()}
                style={{ marginRight: '32px' }}
              >
                Next
              </Button>
            </div>
          </>
        }
      </Box>
    </Modal>
  );
};

export default MFADialog;
